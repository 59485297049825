/*!
	Theme Name:   Theme
	Theme URI:    http://www.besite.nl
	Description:  Theme
	Author:       BeSite
	Author URI:   http://www.besite.nl
	Version:      2.0.0
	Tags:         besite
	Text Domain:  theme
*/


body
{
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    min-width: 320px;
}
img
{
	max-width: 100%;
	height: auto;
}
